<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="items.items"
      item-key="id"
      class="elevation-0 background-transparent"
      :items-per-page="25"
      :loading="loading"
      :options.sync="options"
      :server-items-length="items.meta.totalItems"
      :footer-props="{
        'items-per-page-options': rowsPerPageItemsGlobal
      }"
    >
      <template v-slot:top>
        <div class="d-inline-flex justify-space-between align-center pr-2 mb-2" style="width: 100%">
          <h1 class="text-h4">{{ $lang.header[$route.name] }}</h1>
          <div class="d-inline-flex align-center">
            <v-switch v-model="activeOnly" data-cy="switch-active-only" :label="$lang.labels.activeOnly" class="pl-5 pr-3"></v-switch>
            <v-text-field
              v-model="search"
              hide-details
              outlined
              clearable
              dense
              :label="$lang.labels.searchByName"
              data-cy="search-by-name"
            ></v-text-field>
            <div>
              <v-btn
                text
                light
                color="primary"
                class="ml-2"
                @click="$emit('fetchSettings', { options, type: selectedType, search: search && search.length > 1 ? search : '' })"
              >
                <v-icon
                  dense
                  small
                >
                  mdi-refresh
                </v-icon>
              </v-btn>
            </div>
            <v-btn
              v-if="roles.includes('SETTING_CREATOR')"
              color="accent"
              class="color-accent-text mr-1"
              :to="{ name: 'settingCreate', params: { type: $route.name.toLowerCase().replace('-', '_') } }"
            >{{ $lang.actions.create }}
            </v-btn>
          </div>
        </div>
        <v-divider class="mt-3"></v-divider>
      </template>

      <template v-slot:item.id="{ item }">
        <div class="font-weight-bold"># {{ item.id }}</div>
      </template>

      <template v-slot:item.type="{ item }">
        <div>{{ $lang.status[item.type] }}</div>
      </template>

      <template v-slot:item.createdOn="{ item }">
        <div>{{ item.createdOn | formatDateTime }}</div>
      </template>

      <template v-slot:item.modifiedOn="{ item }">
        <div>{{ item.modifiedOn | formatDateTime }}</div>
      </template>

      <template v-slot:item.status="{ item }">
        <div>{{ $lang.status[item.status] }}</div>
      </template>

      <template v-slot:item.isSystem="{ item }">
        <div>{{ item.isSystem ? $lang.status.YES : $lang.status.NO }}</div>
      </template>

      <template v-slot:item.actions="{ item }">
        <div class="d-inline-flex">
          <v-btn color="secondary" class="mx-1 button-default-width" @click="$emit('changeStatus', {id: item.id, status: item.status})">{{ item.status === 'ACTIVE' ? $lang.actions.deactivate : $lang.actions.activate }}</v-btn>
          <v-btn color="primary" class="mx-0 button-default-width" :to="{ name: 'settingEdit', params: { id: item.id } }">{{ $lang.actions.edit }}</v-btn>
        </div>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import { bus } from '@/main'
export default {
  props: {
    items: {
      type: Object,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      lock: true,
      showTypeSelector: false,
      activeOnly: true,
      search: '',
      options: {},
      totalItems: 0,
      headers: [
        { text: this.$lang.labels.name, value: 'name', sortable: true },
        { text: this.$lang.labels.status, value: 'status', sortable: true },
        { text: this.$lang.labels.system, value: 'isSystem', sortable: true },
        { text: this.$lang.labels.actions, value: 'actions', align: 'end', sortable: false }
      ],
      selectedType: '',
      roles: []
    }
  },
  computed: {
    ...mapState('app', ['rowsPerPageItemsGlobal', 'userRoles', 'userSettings'])
  },
  watch: {
    options: {
      handler () {
        if (!this.lock) this.$emit('fetchSettings', { options: this.options, type: this.selectedType, search: this.search && this.search.length > 1 ? this.search : '' })
        this.savePreFill()
      },
      deep: true
    },
    activeOnly: {
      handler (val) {
        if (!this.lock) this.$emit('activeOnly', { activeOnly: val, options: { options: this.options, type: this.selectedType, search: this.search && this.search.length > 1 ? this.search : '', resetPage: true } })
        this.savePreFill()
        this.options.page = 1
      }
    },
    search: {
      handler (val) {
        if (val && val.length > 1 && !this.lock) this.$emit('fetchSettings', { options: this.options, search: val, resetPage: true })
        if (!this.lock && (val === null || val === '')) this.$emit('fetchSettings', { options: this.options, search: '', resetPage: true })
        this.savePreFill()
        this.options.page = 1
      }
    }
  },
  mounted() {
    bus.$on('refreshData', (name) => {
      if (!this.lock && name === this.$route.name) this.$emit('fetchSettings', { options: this.options, type: this.selectedType, search: this.search && this.search.length > 1 ? this.search : '', resetPage: true })
      this.options.page = 1
    })
  },
  created() {
    this.roles = this.userRoles.map((x) => x.name) || []

    if (this.userSettings.display.showModifiedOn) {
      this.headers.splice(2, 0, { text: this.$lang.labels.modifiedOn, value: 'modifiedOn', sortable: true })
    }

    if (this.userSettings.display.showCreatedOn) {
      this.headers.splice(2, 0, { text: this.$lang.labels.createdOn, value: 'createdOn', class: 'created-on', sortable: true })
    }

    if (this.userSettings.display.showId) {
      this.headers.splice(0, 0, { text: this.$lang.header.id, value: 'id', sortable: true })
    }

    if (localStorage.preFillData) {
      const preFill = JSON.parse(localStorage.preFillData)

      if (preFill && preFill.settingList && preFill.settingList[this.$route.name]) {
        this.search = preFill.settingList[this.$route.name].search
        this.activeOnly = preFill.settingList[this.$route.name].activeOnly
        this.options = preFill.settingList[this.$route.name].options

        this.$emit('activeOnly', { activeOnly: this.activeOnly, options: { options: this.options, search: this.search && this.search.length > 1 ? this.search : '', resetPage: true } })
      } else {
        this.$emit('fetchSettings', { options: this.options, search: this.search && this.search.length > 1 ? this.search : '', resetPage: true })
      }
    } else {
      this.$emit('fetchSettings', { options: this.options, search: this.search && this.search.length > 1 ? this.search : '', resetPage: true })
    }
    setTimeout(() => this.lock = false, 100)
    this.options.page = 1
  },
  methods: {
    savePreFill() {
      if (!localStorage.preFillData) localStorage.preFillData = '{}'
      if (localStorage.preFillData) {
        const preFill = JSON.parse(localStorage.preFillData)

        if (!preFill.settingList) preFill.settingList = {
          frontend: {},
          instance: {},
          process: {}
        }

        if (!preFill.settingList[this.$route.name]) preFill.settingList[this.$route.name] = {
          search: '',
          activeOnly: true,
          options: {
            page: 1,
            itemsPerPage: 25,
            sortBy: [],
            sortDesc: []
          }
        }

        preFill.settingList[this.$route.name].search = this.search
        preFill.settingList[this.$route.name].activeOnly = this.activeOnly
        preFill.settingList[this.$route.name].options = { ...this.options, page: 1 }

        localStorage.preFillData = JSON.stringify(preFill)
      }
    }
  }
}
</script>
